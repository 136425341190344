import React, {useCallback} from 'react';
import {Banner} from '@shopify/polaris';

const MigrateBanner = ({setActive}) => {

  const handleChange = useCallback((prevState) => setActive(!prevState), [setActive]);

  return (
    <div>
      <Banner
        title="Migrate your checkout.liquid upsells to Checkout Extensions"
        action={{
          content: 'Learn more', url: '', onAction() {
            handleChange();
          }
        }}
        status="info"
        onDismiss={() => {
        }}
      >
        <p>Did you upgrade from the checkout.liquid type checkout to the new Checkout Extensibility version? We built a
          migration wizard to copy over your upsells.</p>
      </Banner>
    </div>
  );
};

export default MigrateBanner;